import React from 'react'
import * as SCF from "../../style/StyleComponentFranchise";

const franchiseCost = () => {
  const franchisePtStudioCostData = [
    {value: 1, franchise: 'PT STUDIO', franchiseCostName:'New Paradigm ', franchiseOriginalFee: 1000, franchiseFee: 0, educationOriginalFee: 2000, educationFee: 0, royalty1:90, royalty2:60},
    {value: 2, franchise: 'PT STUDIO', franchiseCostName:'Light Package', franchiseOriginalFee: 1000, franchiseFee: 500, educationOriginalFee: 2000, educationFee: 500, royalty1:60, royalty2:40},
  ];
  const franchisePtFieldCostData = [
    {value: 1, franchiseCostName:'New Paradigm', franchiseOriginalFee: 1000, franchiseFee: 0, educationOriginalFee: 2000, educationFee: 0, royalty1:120, royalty2:90},
    {value: 2, franchiseCostName:'Light Package', franchiseOriginalFee: 1000, franchiseFee: 500, educationOriginalFee: 2000, educationFee: 500, royalty1:90, royalty2:60}
  ];

  return (
    <>
      <SCF.franchiseCostContainer>
        <div className="circle">
          <img
            src={require("../../assets/logo_images/soleilnoir_logo_w.png")}
            alt="logo"
          />
        </div>
        <div className="background">
          <div className="franchise-cost-container">
            <div className="franchise-cost-box">
              <table>
                <tr className='franchise-cost-title-box'>
                  <td></td>
                  <td colSpan={2}>PT STUDIO</td>
                </tr>
                <tr className='franchise-cost-name-box'>
                  <td></td>
                  <td className='franchise-cost-name'>New Paradigm</td>
                  <td className='franchise-cost-name'>Light Package</td>
                </tr>
                <tr>
                  <td className='franchise-cost-title'>
                    가맹비<br/>
                    <span className='cost-fee-desc'>최초 가맹점 오픈을 위한 <span className='mobile'>정보 자료 및 지원</span></span>
                  </td>
                  <td>0원<span className='cost-original-fee mobile'>(1,000만원)</span></td>
                  <td>500만원<span className='cost-original-fee mobile'>(1,000만원)</span></td>
                </tr>
                <tr>
                  <td className='franchise-cost-title'>
                    교육비<br/>
                    <span className='cost-fee-desc'>1차: 솔레노어 브랜드 마케팅 교육</span><br/>
                    <span className='cost-fee-desc'>2차: 솔레노어 지점 운영 교육</span><br/>
                    <span className='cost-fee-desc'>3차: 솔레노어 IT 교육</span>
                  </td>
                  <td>0원<span className='cost-original-fee mobile'>(2,000만원)</span></td>
                  <td>500만원<span className='cost-original-fee mobile'>(2,000만원)</span></td>
                </tr>
                <tr>
                  <td className='franchise-cost-title'>
                    보증금<br/>
                    <span className='cost-fee-important'>계약종료 시 반환</span><br/>
                    <span className='cost-fee-desc'>가맹점 사업자의 귀책 사유 <span className='mobile'>발생시 반환되지 않음</span></span>
                  </td>
                  <td colSpan={2}>500만원 <span className='cost-fee-desc mobile'>(부가세 없음)</span></td>
                </tr>
                <tr className='franchise-cost-white-box'>
                  <td>합계 <span className='cost-fee-desc'>(보증금 제외)</span></td>
                  <td>0원</td>
                  <td>1,000만원</td>
                </tr>
                <tr className='royal-tee-box'>
                  <td className='franchise-cost-title'>로열티</td>
                  <td>
                    3년 계약: 월 90만원<br/>
                    5년 계약: 월 60만원<br/>
                  </td>
                  <td>
                    3년 계약: 월 60만원<br/>
                    5년 계약: 월 40만원<br/>
                    <span className='cost-fee-desc'>영업시작일 부로 <span className='mobile'>로열티 3개월 무료</span></span>
                  </td>
                </tr>
                <tr className='text-box'>
                  <td colSpan={3}>
                    보증금을 제외한 모든 금액은 부가세가 포함되지 않은 금액 입니다.<br/>
                    자세한 내용은 하단의 문의하기를 통해 받아보실 수 있습니다.
                  </td>
                </tr>
              </table>
            </div>
            <div className="franchise-cost-box pt-field-box">
              <table>
                <tr className='franchise-cost-title-box pt-field-title'>
                  <td></td>
                  <td colSpan={2}>PT FIELD</td>
                </tr>
                <tr className='franchise-cost-name-box'>
                  <td></td>
                  <td className='franchise-cost-name'>New Paradigm</td>
                  <td className='franchise-cost-name'>Light Package</td>
                </tr>
                <tr>
                  <td className='franchise-cost-title'>
                    가맹비<br/>
                    <span className='cost-fee-desc'>최초 가맹점 오픈을 위한 <span className='mobile'>정보 자료 및 지원</span></span>
                  </td>
                  <td>0원<span className='cost-original-fee mobile'>(1,000만원)</span></td>
                  <td>500만원<span className='cost-original-fee mobile'>(1,000만원)</span></td>
                </tr>
                <tr>
                  <td className='franchise-cost-title'>
                    교육비<br/>
                    <span className='cost-fee-desc'>1차: 솔레노어 브랜드 마케팅 교육</span><br/>
                    <span className='cost-fee-desc'>2차: 솔레노어 지점 운영 교육</span><br/>
                    <span className='cost-fee-desc'>3차: 솔레노어 IT 교육</span>
                  </td>
                  <td>0원<span className='cost-original-fee mobile'>(2,000만원)</span></td>
                  <td>500만원<span className='cost-original-fee mobile'>(2,000만원)</span></td>
                </tr>
                <tr>
                  <td className='franchise-cost-title'>
                    보증금<br/>
                    <span className='cost-fee-important'>계약종료 시 반환</span><br/>
                    <span className='cost-fee-desc'>가맹점 사업자의 귀책 사유 <span className='mobile'>발생시 반환되지 않음</span></span>
                  </td>
                  <td colSpan={2}>500만원 <span className='cost-fee-desc mobile'>(부가세 없음)</span></td>
                </tr>
                <tr className='franchise-cost-white-box'>
                  <td>합계 <span className='cost-fee-desc'>(보증금 제외)</span></td>
                  <td>0원</td>
                  <td>1,000만원</td>
                </tr>
                <tr className='royal-tee-box'>
                  <td className='franchise-cost-title'>로열티</td>
                  <td>
                    3년 계약: 월 120만원<br/>
                    5년 계약: 월 90만원<br/>
                  </td>
                  <td>
                    3년 계약: 월 90만원<br/>
                    5년 계약: 월 60만원<br/>
                    <span className='cost-fee-desc'>영업시작일 부로 <span className='mobile'>로열티 3개월 무료</span></span>
                  </td>
                </tr>
                <tr className='text-box'>
                  <td colSpan={3}>
                    보증금을 제외한 모든 금액은 부가세가 포함되지 않은 금액 입니다.<br/>
                    자세한 내용은 하단의 문의하기를 통해 받아보실 수 있습니다.
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SCF.franchiseCostContainer>
    </>
  )
}

export default franchiseCost