import styled from "styled-components";
import theme from "./theme";
import { backend_url } from "../module/DevInformation";

export const HeaderContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  gap: 10vw;
  line-height: 50px;
  background-color: transparent;
  backdrop-filter: blur(10px);
  z-index: 1000;

  & .logo-box {
    cursor: pointer;

    & img {
      width: auto;
      height: 45px;
    }
  }

  & .chapter-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    height: 100%;

    & .chapter {
      cursor: pointer;
      padding: 10px;
      font-size: 17px;
      font-weight: 500;
      color: ${theme.color.mainWhite};
      transition: color 0.3s;

      &:hover {
        color: ${theme.color.pointGold};
      }
    }
  }
  & .franchise-menu {
    width: 120px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    background-color: ${theme.color.pointGold};
    border: 1px solid ${theme.color.pointGold};
    border-radius: 20px;
    font-weight: 500;
  }
`;

export const MobileHeaderContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: ${theme.color.mainWhite};
  box-shadow: ${theme.shadow.matte};
  z-index: 1000;

  & .logo-box {
    cursor: pointer;

    & img {
      width: auto;
      height: 28px;
    }
  }
`;

export const DefaultContainer = styled.div`
  position: relative;
  padding-top: 80px;
  width: 100%;
  min-height: 45vh;
  overflow-x: hidden;
  
  @media ${theme.screen.sm} {
    padding-top: 40px;
  }
`;

export const ReasonThreeCircle = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  gap: 5%;

  & .main-section {
    position: relative;
    width: 50%;
    background-color: white;

    & .circle {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 300px;
      height: 300px;
      border-radius: 50%;
      opacity: 0.5;
    }

    & .machine {
      background-color: red;
      animation: machine 1s;
      transform: translate(-50%, -100%);
    }
    & .interior {
      background-color: blue;
      animation: interior 1s;
      transform: translate(-75%, -55%);
    }
    & .real-estate {
      background-color: green;
      animation: realEstate 1s;
      transform: translate(-25%, -55%);
    }

    @keyframes machine {
      0% {
        transform: translate(-50%, -50%);
      }
      100% {
        transform: translate(0%, 0%);
      }
    }
    @keyframes interior {
      0% {
        transform: translate(-50%, -50%);
      }
      100% {
        transform: translate(0%, -50%);
      }
    }
    @keyframes realEstate {
      0% {
        transform: translate(-50%, -50%);
      }
      100% {
        transform: translate(0%, 50%);
      }
    }
  }

  & .side-section {
    width: 20%;
  }
`;

export const CostChapter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  width: 100%;

  & .cost-page {
    position: relative;
    width: 320px;
    height: 550px;
    background-color: ${theme.color.mainWhite};

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 12px;
      background:
        linear-gradient(-135deg, #000 6px, transparent 0%),
        linear-gradient(135deg, #000 6px, transparent 0%);
      background-size: 12px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 12px;
      background:
        linear-gradient(-45deg, #000 6px, transparent 0%),
        linear-gradient(45deg, #000 6px, transparent 0%);
      background-size: 12px;
    }

    & .head-part {

      & .logo-box {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        width: 100%;
  
        & img {
          width: 100px;
        }
      }
  
      & .program-title {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
  
        & h6 {
          padding: 4px 12px;
          font-size: 15px;
          font-weight: 300;
          background-color: #000;
          letter-spacing: 0.8px;
        }
      }
  
      & .sub-title {
        padding: 15px 0;
        text-align: center;
        color: #000;
        line-height: 22px;
        letter-spacing: 0.4px;
      }
    }

    & .content-part {
      color: #000;
      width: 80%;

      & .double-line {
        height: 4px;
        border-top: #000 1px solid;
        border-bottom: #000 1px solid;
      }

      & .description-box {
        padding: 10px 0;
        width: 100%;

        & h6 {
          margin: 4px 0;
          font-size: 13.5px;
          line-height: 17px;
        }
      }

      & .cost-box {
        width: 100%;
        border-top: 1.8px solid #000;

        & .cost-text {
          display: flex;
          justify-content: space-between;
          border-color: ${theme.color.mainBlack};
          border-style: solid;
          line-height: 32px;
        }
      }
    }

  }
`;

export const DifferenceContainer = styled.div`
  width: 100%;
  height:100vh;
  @media ${theme.screen.sm} {
    height: auto;
  }
  & .text-container{
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 45px;
    @media ${theme.screen.sm} {
      gap: 5vh;
    }
    & .background {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: auto;
      z-index: -1;
      filter: brightness(30%);
      min-width: 630px;
      @media ${theme.screen.sm} {
        height: 100%;
        object-fit: cover;
        left: 80%;
        filter: brightness(20%);
      }
    }
    & .title {
      font-size: 3em;
      text-align: center;
      @media ${theme.screen.sm} {
        display: none;
      }
      & span {
        @media ${theme.screen.sm} {
          display: block;
        }
      }
    }
    & .subTitle {
      display: inline-block;
      margin: 0 auto;
      background-color: ${theme.color.OpacityBlack};
      padding: 15px 50px;
      border-radius: 10px;
      text-align: center;
      line-height: 30px;
      @media ${theme.screen.sm} {
        background-color: initial;
      }
      & b {      
        font-size: 1.7em;
        font-weight: bold;
        color: ${theme.color.pointGold};}
    }
    & ul {
      display: flex;
      gap: 30px;
      justify-content: center;
      @media ${theme.screen.sm} {
        flex-wrap: wrap;
        gap: 10px;
      }
      & li {
        width: 25%;
        @media ${theme.screen.sm} {
          width: 100%;
          padding-bottom: 10px;
          border-bottom: 1px solid ${theme.color.mainWhite};
        }
        & .list-title {
          color: ${theme.color.pointGold};
          font-size: 1.5em;
          @media ${theme.screen.md} {
            font-size: 1.3em;
          }
          & .list-title-b {
            font-size: 2em;
            font-weight: bold;
            margin-right: 5px;
          }
        }
        & .list-subTitle {
          font-size: 16px;
          font-weight: lighter;
          line-height: 23px;
          padding-block: 20px;
          @media ${theme.screen.md} {
            color: ${theme.color.pointGold};
            font-weight: initial;
          }
          & span {
            display: block;
            @media ${theme.screen.md} {
              display: inline;
            }
          }
        }
        & .list-desc {
          font-size: 15px;
          font-weight: lighter;
          line-height: 23px;
          & .list-desc-b {
            font-weight: initial;
          }
        }
      }
    }
  }
`;

export const WhySoleilnoirContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;

  @media ${theme.screen.sm} {
    margin-top: 100px;
  }

  & .logo-box {
    position: absolute;
    left: 5%;
    bottom: 18%;
    width: 140px;

    @media ${theme.screen.sm} {
      display: none;
    }
  }

  & .section-container {
    width: 70%;
    padding-right: 10%;

    & .item-box {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 10vh;
      height: 100px;
      border-bottom: 1px solid ${theme.color.subGray02};

      &:nth-child(1) {
        border-top: 1px solid ${theme.color.subGray02};
      }
      
      & .title {
        position: relative;
        font-size: 28px;
        font-weight: 500;
      }

      & .summary {
        line-height: 20px;
        word-break: keep-all;
      }
    }

    @media ${theme.screen.sm} {
      width: 100%;
      padding: 0 3%;

      & .item-box {
        display: block;
        height: 120px;

        & .title {
          display: flex;
          gap: 20px;
          padding: 20px 0;
          font-size: 24px;
        }

        & .summary {
          font-size: 14px;
        }
      }
    }
  }
`;

export const ModelContainer = styled.div`
  margin-block: 100px;
  width: 100%;
  height: auto;
  & .product-container {
    width: 80%;
    max-width: 1200px;
    margin: -50px auto 0;
    @media ${theme.screen.sm} {
      width: 100%;
    }
    & .product-item {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      position: relative;
      @media ${theme.screen.sm} {
          align-items: center;
      }
      & img{
        width: 50%;
        filter: brightness(70%);
        @media ${theme.screen.sm} {
          position: absolute;
          z-index: -1;
          width: 65%;
          min-width: 350px;
          filter: brightness(40%);
        }
      }
      & .text-box {
        width: 50%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;
        @media ${theme.screen.sm} {
          width: 90%;
          padding-block: 20px;
        }
        & .product-name {
          font-size: 4.5em;
          font-weight: bold;
          color: ${theme.color.mainWhite};
          text-shadow: 0 0 5px ${theme.color.pointGold},
                   0 0 10px ${theme.color.pointGold},
                   0 0 20px ${theme.color.pointGold},
                   0 0 30px ${theme.color.pointGold},
                   0 0 40px ${theme.color.pointGold};
          @media ${theme.screen.md} {
            font-size: 2.5em;
          }
        }
        & .product-desc {
          font-weight: lighter;
          line-height: 25px;
          @media ${theme.screen.md} {
            font-size: 13px;
          }
          & b {
            color: ${theme.color.pointGold};
            font-weight: bold;
            font-size: 1.5em;
          }
        }
      }
    }
  }

`;

export const RequestContainer = styled.div`
  width: 100%;
  padding-block: 10vh;
  color: ${theme.color.mainBlack};
  background: url(${backend_url}/public/image_folder/homepage_images/franchise/6780b551b7572489e24c1bc90fe7412db50f146bc45a37bfcee0cc9b74b1720cef176e7995677d3ac5434c41744235772f7d06013c20e1f7a06ae75748040a43.jpg) center/cover;
  background-color: ${theme.color.mainBlack};
  position: relative;
  @media ${theme.screen.sm} {
    background-position: top 50% left 20%;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.color.realBlack};
    opacity: 70%;
  }
  & .request-container {
    width: 600px;
    padding: 30px 60px;
    background-color: ${theme.color.OpacityWhite07};
    border-radius: 50px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    @media ${theme.screen.sm} {
      width: 100%;
      padding: 30px;
      background: none;
    }
    & img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      opacity: 20%;
      width: 50%;
      pointer-events: none;
      @media ${theme.screen.sm} {
        display: none;
      }
    }
    & .title {
      font-size: 3em;
      font-weight: bold;
      padding-block: 20px;
      border-bottom: 1px solid ${theme.color.mainBlack};
      text-align: center;
      @media ${theme.screen.sm} {
        color: ${theme.color.mainWhite};
        border-color: ${theme.color.mainWhite};
      }
    }
    & .content {
      padding-block: 10px;
      & .label {
        padding-block: 10px;
        @media ${theme.screen.sm} {
          color: ${theme.color.mainWhite};
          font-weight: lighter;
        }
      }
      & .input, textarea {
        width: 100%;
        & input {
          padding: 17px 10px;
          width: 100%;
          background-color: ${theme.color.OpacityWhite07};
        }
      }
      & textarea {
        background-color: ${theme.color.OpacityWhite07};
      }
      & .select {
        display: flex;
        justify-content: space-between;
        & button {
          width: 45%;
          padding-block: 15px;
          font-size: 20px;
          background-color: ${theme.color.OpacityWhite07};
          transition: 0.3s;
        }
        & button.active {
          background-color: ${theme.color.mainBlack};
          color: ${theme.color.mainWhite};
        }
      }
    }
    & .privacy {
      display: flex;
      justify-content: end;
      align-items: center;
      margin-top: 16px;
      font-size: 14px;
      gap: 5px;
      & span {
        @media ${theme.screen.sm} {
          font-weight: lighter;
          color: ${theme.color.mainWhite};
        }
      }
    }
    & .submit-box {
      width: 100%;
      padding-block: 15px;
      font-size: 1.5em;
      background-color: ${theme.color.mainBlack};
      color: ${theme.color.mainWhite};
      text-align: center;
    }
  }
`;

export const IntroFranchiseContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  & .landing-background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), #000);
    }
  }

  & .marker {
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0;

    & img {
      position: absolute;
      width: 20px;
      height: 30px;
    }
  }

  & .slogan-box {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    text-align: center;

    & h1 {
      font-size: 36px;
      /* padding-left: 5%; */
      padding-top: 15%;
      
      @media ${theme.screen.sm} {
        font-size: 20px;
        line-height: 50vh;
      }
    }
  }
`;

export const ITContainer = styled.div`
  width: 100%;
  & .container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    & .title {
      font-size: 3em;
      text-align: center;
      line-height: 55px;
      margin-bottom: 20px;
      & b {
        color: ${theme.color.mainWhite};
        text-shadow: 0 0 5px ${theme.color.pointGold},
                     0 0 10px ${theme.color.pointGold},
                     0 0 20px ${theme.color.pointGold},
                     0 0 30px ${theme.color.pointGold},
                     0 0 40px ${theme.color.pointGold};
      }
    }
    & .subTitle {
      text-align: center;
      color: ${theme.color.pointGold};
      font-size: 1.5em;
      @media ${theme.screen.sm} {
        margin-bottom: 50px;
      }
    }
    & .img-box {
      position: relative;
      height: 470px;
      @media ${theme.screen.sm} {
        display: none;
      }
      & img {
        position: absolute;
        bottom: 0;
      }
      & .mobile {
        left: 15%;
        width: 120px;
      }
      & .web {
        z-index: -1;
        width: 475px;
        left: 50%;
        transform: translateX(-50%);
      }
      & .tablet {
        width: 360px;
        right: 5%;
      }
    }
    & .app-box {
      display: flex;
      justify-content: center;
      @media ${theme.screen.sm} {
        flex-direction: column;
      }
      & .app {
        width: 33%;
        @media ${theme.screen.sm} {
          width: 80%;
          margin: 0 auto 40px;
          position: relative;
        }
        & img {
          display: none;
          @media ${theme.screen.sm} {
            position: absolute;
            bottom: -20px;
            right:0;
            display: block;
            height: 235px;
            object-fit: cover;
            z-index: -1;
            filter: brightness(35%);
          }
        }
        & img.tablet {
          @media ${theme.screen.sm} {
            height: 190px;
          }
        }
        & .app-title-box {
          width: 80%;
          margin: 0 auto;
          padding-block: 28px;
          border-bottom: 1px solid;
          border-image: linear-gradient(90deg, transparent 0%, #7F6346 20%, #CEA57E 50%, #7F6346 80%, transparent 100%) 1;
          text-align: center;
          @media ${theme.screen.sm} {
            text-align: initial;
            width: 100%;
            border: none;
          }
          & .app-title {
            font-size: 20px;
            line-height: 30px;
            @media ${theme.screen.sm} {
              font-size: 35px;
              line-height: 45px;
              color: ${theme.color.pointGold};
            }
          }
          & .app-subTitle {
            font-weight: lighter;
            @media ${theme.screen.sm} {
              font-size: 23px;
            }
          }
        }
        & ul {
          padding-top: 28px;
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 10px;
          @media ${theme.screen.sm} {
            text-align: initial;
            font-size: 13px;
            padding: initial;
            color: ${theme.color.OpacityWhite07};
          }
          & li {
            font-weight: lighter;
          }
          & li.none {
            @media ${theme.screen.sm} {
            display: none;
            }
          }
        }
      }
    }
  }
  & .logo-text-box {
    position: relative;
    padding-block: 90px;
    & img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 25%;
      opacity: 15%;
      @media ${theme.screen.sm} {
        width: 60%;
      }
    }
    & p {
      font-size: 1.5em;
      text-align: center;
      line-height: 35px;
      @media ${theme.screen.sm} {
        font-size: 1.1em;
      }
    }
  }
  & .rect {
      width: 50px;
      height: 10px;
      background-color: ${theme.color.mainWhite};
      margin: 50px auto;
      margin-bottom: 50px;
  }
`;

export const ITTabComponent = styled.div`
  position: relative;
  margin:7.5% auto 0;
  width: 100%; max-width: 1200px;
  height: 85%;
  list-style: none;
  display: flex; justify-content: space-around;
  padding-inline: 10%;
  @media ${theme.screen.sm} {height:75%;}

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.6));
    z-index: 1;
  }

  & .content-box {
    display: flex;
    align-items: center;
    height: 100%;

    & h2 {
      font-size: 36px;
      line-height: 60px;

      @media ${theme.screen.sm} {
        font-size: 24px;
        line-height: 40px;
      }
    }

    & h3 {
      font-size: 32px;
      color: ${theme.color.pointGold};

      @media ${theme.screen.sm} {
       font-size: 21px;
      }
    }

    & span {
      font-size: 20px;
      line-height: 32px;
      font-weight: 300;
      color: ${theme.color.pointGold};

      @media ${theme.screen.sm} {
        font-size: 16px;
        line-height: 26px;
      }
    }

    & .list-item {

      & img {
        width: 15px;

        @media ${theme.screen.sm} {
          width: 12px;
        }
      }
    }
  }

  & .content-animation-0 {
    
    & .title-box {
      animation: fadeUp1 1s;

      @keyframes fadeUp1 {
        0% {opacity: 0; transform: translateY(-20px);}
        100% {opacity: 1; transform: translateY(0px);}
      }
    }

    & .content-line {
      width: 400px;
      height: 1px;
      border-top: 1px solid ${theme.color.pointGold};
      animation: scaleLine-0 0.7s ease-in;
      margin: 20px 0;

      @keyframes scaleLine-0 {
        0% {width: 0px;} 100% {width:400px;}
      }
      
      @media ${theme.screen.sm} {
        width: 260px;
        margin: 10px 0;
        animation: scaleLine-0 1s ease-in;

        @keyframes scaleLine-0 {
          0% {width: 0px;} 100% {width:260px;}
        }
      }
    }

    & .list-item {
      animation: listOpacity0 1s;

      @keyframes listOpacity0 {
        0% {opacity: 0;}
        100% {opacity: 1;}
      }
    }
  }
  & .content-animation-1 {

    & .title-box {
      animation: fadeUp2 1s;

      @keyframes fadeUp2 {
        0% {opacity: 0; transform: translateY(-20px);}
        100% {opacity: 1; transform: translateY(0px);}
      }
    }

    & .content-line {
      width: 400px;
      height: 1px;
      border-top: 1px solid ${theme.color.pointGold};
      animation: scaleLine-1 1s ease-in;
      margin: 20px 0;

      @keyframes scaleLine-1 {
        0% {width: 0px;} 100% {width:400px;}
      }

      @media ${theme.screen.sm} {
        width: 260px;
        margin: 10px 0;
        animation: scaleLine-1 1s ease-in;

        @keyframes scaleLine-1 {
          0% {width: 0px;} 100% {width:260px;}
        }
      }
    }

    & .list-item {
      animation: listOpacity1 1s;

      @keyframes listOpacity1 {
        0% {opacity: 0;}
        100% {opacity: 1;}
      }
    }
  }
  & .content-animation-2 {

    & .title-box {
      animation: fadeUp3 1s;

      @keyframes fadeUp3 {
        0% {opacity: 0; transform: translateY(-20px);}
        100% {opacity: 1; transform: translateY(0px);}
      }
    }

    & .content-line {
      width: 400px;
      height: 1px;
      border-top: 1px solid ${theme.color.pointGold};
      animation: scaleLine-2 1s ease-in;
      margin: 20px 0;

      @keyframes scaleLine-2 {
        0% {width: 0px;} 100% {width:400px;}
      }

      @media ${theme.screen.sm} {
        width: 260px;
        margin: 10px 0;
        animation: scaleLine-2 1s ease-in;

        @keyframes scaleLine-2 {
          0% {width: 0px;} 100% {width:260px;}
        }
      }
    }

    & .list-item {
      animation: listOpacity2 1s;

      @keyframes listOpacity2 {
        0% {opacity: 0;}
        100% {opacity: 1;}
      }
    }
  }

  & .it-background {
    width: 40%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    @media ${theme.screen.sm} {position:absolute; right:0; bottom:0; z-index:-1; width: 50%;}
  }

  & .show-animation-0 {
    height: 85%;
    animation: show-0 1s;
    right: 0%;
  
    @keyframes show-0 {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  & .show-animation-1 {
    animation: show-1 1s;
  
    @keyframes show-1 {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  & .show-animation-2 {
    animation: show-2 1s;
  
    @keyframes show-2 {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

export const ReviewContainer = styled.div<{$browserWidth: number}>`
  width: 100%;
  padding: 10vh 0;
  display: flex;
  gap:2%;
  background: url(${backend_url}/public/image_folder/homepage_images/review_images/6c9b5fd3a9f3999baf6cd19aa68d2ac65869e24076ef65ea107a1e05b826b0444e520cbda473191f663b124bdeb1671aeaa96a576a85af73ca2e5c53af5612fe.png) center/cover;

  
  @media ${theme.screen.lg} {
    display: block;
  }

  & .review_description_section {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: end;
    width: 48%;
    padding-bottom:50px;

    @media ${theme.screen.lg} {
      width: 100%;
    }

    & .text-flex-box {
      
      @media ${theme.screen.lg} {
        width: 100%;
        display: flex;
      }
      @media ${theme.screen.sm} {
        flex-direction: column;
      }

      & .text-box {
        text-align: center;
        padding: 2vh 4vh;
        width: 100%;
        backdrop-filter: blur(5px) brightness(80%);
        /* border-top: 1px solid ${theme.color.mainWhite}; */
        /* border-bottom: 1px solid ${theme.color.mainWhite}; */

        @media ${theme.screen.sm} {
          padding: 20px 0;
          margin-bottom: 40px;
        }
  
        & h3 {
          font-size: 20px;
          line-height: 40px;
          color: ${theme.color.subGray02};
          font-weight: lighter;

          @media ${theme.screen.sm} {
            font-size: 14px;
            line-height: 24px;
          }
        }
        & .text-box-title {font-size:23px; font-weight:bold;}
      }
      & .text-box:first-child {
        margin-bottom:1vh;
        @media ${theme.screen.lg} {margin:initial;}
      }
    }
  }

  & .review_image_section {
    position: relative;
    display: flex;
    width: 50%;
    padding: 50px 20px;
    overflow: hidden;

    @media ${theme.screen.lg} {
      width: 100%;
      padding-bottom: 70px;
    }

    & .slider-button {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 100%;
      z-index: 100;
      padding: 15px;

      & img {
        display: none;
        width: 40px;

        @media ${theme.screen.sm} {
          display: block;
        }
      }

      &.prev {
        left: 0;
        
        & img {
          transform: translate(-35px, -50px) rotate(90deg);
        }
      }
      &.next {
        right: 0;

        & img {
          transform: translate(35px, -50px) rotate(270deg);
        }

        @media ${theme.screen.lg} {
          width: 43%;
        }

        @media ${theme.screen.sm} {
          width: 25%;
        }
      }
    }

    & .carousel-container {
      display: flex;
      padding: 10% 0;
      padding-left: 260px;
      gap: 100px;
      transition: all 0.5s;

      @media ${theme.screen.sm} {
        padding-left: ${props => (props.$browserWidth-280)/2}px;
        gap: 40px;
      }

      & .review_item {
        position: relative;
        width: 260px;
        user-select: none;
        border: 2px solid ${theme.color.pointGold};
        transition: all 0.4s;

        @media ${theme.screen.sm} {
          width: 240px;
        }

        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to top, transparent, transparent, transparent, rgba(0, 0, 0, 1));
        }
      }

      & .active {
        transform: scale(1.4);
        z-index: 11;
      }

      & .inactive {
        width: 260px;
        transform: scale(1);
        filter: blur(3px);

        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.4);
        }

        @media ${theme.screen.sm} {
          width: 240px;
        }
      }
    }
    
    & .review-text {
      position: absolute;
      width: 100%;
      height: 20px;
      left: 0;
      bottom: 0;
      font-size: 22px;
      text-align: center;
      font-family: ${theme.font.pretendard};

      @media ${theme.screen.sm} {
        font-size: 16px;
      }
    }
  }
`;

export const ProcessContainer = styled.div`
  width: 100%;
  background-color: ${theme.color.mainBlack};
  padding-block:80px;
  & .title {
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 30px;
  }
  & .process-container {
    display: flex;
    justify-content: center;
    width: 100%;
    @media ${theme.screen.sm} {
      flex-direction:column;
    }

    & .process-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18%;
      @media ${theme.screen.sm} {
        width: 100%;
      }

      & .process-step {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        width: 100%;
        height: 300px;
        @media ${theme.screen.sm} {
          flex-wrap:wrap; 
          flex-direction:row;
          justify-content:space-around;
          align-items: center;
          width: 80%; height: 130px;
          margin:0 auto;
        }

        & .text-index {
          font-size: 72px;
          text-align: center;
          @media ${theme.screen.sm} {
            line-height:150px;
          }
        }

        & .text-kor {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-left: 5%;
          color: ${theme.color.pointGold};
          font-size: 32px;
          line-height: 40px;
          text-align: center;
          @media ${theme.screen.sm} {
            padding:initial;
            font-size: 18px;
            line-height: 30px;
          }
        }

        & .text-eng {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          @media ${theme.screen.md} {
            display:none;
          }
        }

        &:before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.1);
          clip-path: polygon(80% 0, 100% 50%, 80% 100%, 0% 100%, 20% 50%, 0% 0%);
          @media ${theme.screen.sm} {
            clip-path: polygon(0 0, 50% 20%, 100% 0, 100% 80%, 50% 100%, 0 80%);
          }
        }
      }
      & .sc-higXfs {
        @media ${theme.screen.md} {
          width: 100%;
        }
      }
    }
  }
`;

export const FranchiseInstructorContainer=styled.div`
  width: 100%;
  & .container {
    width: 80%;
    max-width: 1200px;
    margin:0 auto;
    @media ${theme.screen.sm} {
      width: 90%;
    }
    & .img-box {
      display: flex;
      gap: 5%;
      flex-wrap: wrap;
      & img {
        width: 30%;
        margin-bottom: 5vh;
        @media ${theme.screen.sm} {
          width: 47.5%;
        }
      }
    }
    & .text-box {
      position: relative;
      & .line {
        position: absolute;
        top: -5vh;
        left: 50%;
        transform: translateX(-50%);
        width: 1px;
        height: 150%;
        background-color: ${theme.color.pointGold};
        z-index: -1;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 12px;
          height: 12px;
          background-color: ${theme.color.pointGold};
          border-radius: 50%;
        }
      }
      & ul {
        font-size: 15px;
        padding-block: 35px;
        width: 60%;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-image: linear-gradient(90deg, transparent 0%, #7F6346 20%, #CEA57E 50%, #7F6346 80%, transparent 100%) 1;
        background-color: ${theme.color.realBlack};
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0 auto;
        @media ${theme.screen.sm} {
          width: 85%;
        }
        & li {
          color: ${theme.color.OpacityWhite07};
          text-align: center;
        }
      }
    }
    & .logo-text-box {
      position: relative;
      padding-block: 90px;
      & img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 25%;
        opacity: 15%;
        @media ${theme.screen.sm} {
          width: 85%;
        }
      }
      & p {
        font-size: 1.5em;
        text-align: center;
        font-weight: lighter;
        line-height: 30px;
        @media ${theme.screen.sm} {
          font-size: 1.1em;
        }
        & b {
          font-weight: bold;
          color: ${theme.color.pointGold};
        }
      }
    }
  }
`;

export const FranchiseInstructorQA = styled.div`
  width: 100%;
  & .QAcontainer {
    width: 100%;
    margin:0 auto;
    background-color: ${theme.color.mainBlack};
    padding-block: 50px;
    & .qa-box {
      margin: 0 auto 50px;
      width: 90%;
      max-width: 1200px;
      display: flex;
      justify-content: center;
      &:last-child {
        margin: 0 auto;
      }
      @media ${theme.screen.sm} {
        flex-direction: column;
      }
      & .box {
        width: 50%;
        position: relative;
        @media ${theme.screen.sm} {
         width: 100%;
        }
        & .background-text {
          color: ${theme.color.mainWhite};
          opacity: 20%;
          font-size: 20em;
          text-align: center;
          font-weight: bold;
          @media ${theme.screen.sm} {
            font-size: 13em;
          }
        }
        & .text {
          position: absolute;
          top: 44%;
          left: 50%;
          transform: translate(-50%,-50%);
          text-align: center;
          font-weight: lighter;
          line-height: 23px;
          width: 100%;
          & .bold {
            font-weight: initial;
          }
          & .color-bold {
            color: ${theme.color.pointGold};
            font-weight: initial;
          }
        }
        & .q-text {
          font-size: 1.2em;
          line-height: 30px;
        }
        & .a-text {
          @media ${theme.screen.sm} {
            padding-bottom: 30px;
            border-bottom: 1px solid ${theme.color.OpacityWhite07};
          }
        }
      }
    }
  }
  & .text-box {
      position: relative;
      padding-block: 90px;
      & img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 25%;
        opacity: 15%;
        @media ${theme.screen.sm} {
          width: 85%;
        }
      }
      & p {
        font-size: 1.5em;
        text-align: center;
        font-weight: lighter;
        line-height: 30px;
        @media ${theme.screen.sm} {
          font-size: 16px;
          line-height: 23px;
        }
        & .color-bold {
          font-weight: bold;
          color: ${theme.color.pointGold};
        }
      }
    }
`;

export const franchiseCostContainer = styled.div `
  width: 100%;
  position: relative;
  overflow: hidden;
  & .circle {
    position: absolute;
    top: -13px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    background-color: ${theme.color.mainBlack};
    width: 100%;
    height: 100vw;
    clip-path: ellipse(51% 50% at 50% 55%);
    padding-top: 20%;
    & img {
      width: 40%; 
      max-width: 300px;
      margin: 10vh auto;
      @media ${theme.screen.sm} {
        margin: 0 auto;
      }
    }
  }
  & .background {
    margin-top: 50vw;
    width: 100%;
    background-color: ${theme.color.mainBlack};
    padding-bottom: 100px;
    @media ${theme.screen.md} {
      margin-top: 60vw;
    }
    & .franchise-cost-container {
      width: 90%;
      max-width: 1200px;
      margin: 0 auto;
      @media ${theme.screen.sm} {
        width: 100%;
      }
      & .franchise-cost-box {
        width: 100%;
        margin: 0 auto 100px;
        padding: 30px;
        box-shadow: 0px 0px 30px -3px ${theme.color.pointGold};
        border-radius: 20px;
        @media ${theme.screen.sm} {
          box-shadow: initial;
          padding: 10px;
          margin: 0 auto 50px;
        }
        & table {
          width: 100%;
          & .franchise-cost-title-box {
            text-align: center;
            font-size: 3em;
            font-weight: bold;
            color: ${theme.color.mainWhite};
            text-shadow: 0 0 5px ${theme.color.pointGold},
                     0 0 10px ${theme.color.pointGold},
                     0 0 20px ${theme.color.pointGold},
                     0 0 30px ${theme.color.pointGold},
                     0 0 40px ${theme.color.pointGold};
            & td {
              padding-bottom: 30px;
            }
          }
          & tr {
            & td {
              @media ${theme.screen.sm} {
                &:not(.franchise-cost-title-box td) {
                  font-size: 14px;
                }
              }
              & .mobile {
                @media ${theme.screen.md} {
                  display: block;
                }
              }
            }
          }
          & tr:not(:first-child, :nth-child(2), :last-child) {
            border-bottom: 1px solid ${theme.color.mainWhite};
            & td:nth-child(2), td:nth-child(3)  {
              width: 35%;
              text-align: center;
              @media ${theme.screen.sm} {
                width: 30%;
              }
            }
            & .franchise-cost-title {
              padding-block: 10px;
              line-height: 25px;
            }
            & .cost-fee-desc {
              font-size: 14px;
              line-height: 23px;
              color:${theme.color.subGray02};
              @media ${theme.screen.sm} {
                font-size: 12px;
              }
            }
            & .cost-fee-important {
              display: inline-block;
              font-size: 15px;
              padding: 3px 5px;
              background-color: ${theme.color.SunColor};
              border-radius: 5px;
              font-weight: lighter;
              margin-block: 5px;
            }
            & .cost-original-fee {
              margin-left: 3px;
              font-size: 15px;
              text-decoration: line-through;
              color:${theme.color.subGray02};
              @media ${theme.screen.md} {
                margin-top: 10px;
              }
            }
          }
          & .franchise-cost-name-box {
            border-bottom: initial;
            & .franchise-cost-name {
              text-align: center;
              padding-block: 10px;
              margin-block: 20px;
              color: ${theme.color.mainBlack};
              font-size: 18px;
              background-color: ${theme.color.mainWhite};
              @media ${theme.screen.sm} {
                font-size: 15px;
              }
            }
          }
          & .franchise-cost-white-box {
            background-color: ${theme.color.mainWhite};
            color: ${theme.color.SunColor};
            font-weight: bold;
            font-size: 18px;
            & td {
              padding: 10px 10px;
              & .cost-fee-desc {
                color: ${theme.color.darkGray02};
                font-weight: lighter ;
              }
            }
          }
          & .royal-tee-box {
            & td {
              line-height: 25px;
              padding-bottom: 10px;
            }
          }
          & .text-box {
            text-align: center;
            & td {
              padding-block: 10px;
              line-height: 25px;
            }
          }
        }
      }
      & .pt-field-box {
        box-shadow: 0px 0px 30px -3px ${theme.color.EarthColor};
        @media ${theme.screen.sm} {
          box-shadow: initial;
        }
        & table {
          & .pt-field-title {
            text-shadow: 0 0 5px ${theme.color.EarthColor},
                     0 0 10px ${theme.color.EarthColor},
                     0 0 20px ${theme.color.EarthColor},
                     0 0 30px ${theme.color.EarthColor},
                     0 0 40px ${theme.color.EarthColor};
          }
        }
      }
    }
  }
`;